"use client";

import { useEffect } from "react";
import LogRocket from "logrocket";

export function LogRocketInit() {
  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.location.hostname !== "localhost"
    ) {
      LogRocket.init("e5ud8k/wildmavins", {
        release: process.env.VERCEL_GIT_COMMIT_SHA || "unknown",
      });
    }
  }, []);

  return null;
}
